<template>
  <div class="manage-users-detail">
    <div class="container fluid">
      <h1 class="mx-2 my-4">User Detail</h1>
      <div class="card p-3">
        <div class="row">
          <div class="col-12">
            <chip
              :class="[
                'fg-white mb-2',
                user.isActive ? 'bg-success' : 'bg-warn'
              ]"
            >
              {{ user.isActive ? "Active" : "Inactive" }}
            </chip>
          </div>
          <div
            class="col-12 d-flex justify-content-between align-items-center px-1 mb-3"
          >
            <div class="h5">
              <span class="font-bold">{{ user.name }}</span>
              <chip class="bg-accent2 fg-white mt-1 ml-2">{{
                user.rolePermission.name
              }}</chip>
            </div>
            <fd-button @click="toEditPage(user.id)">
              <i class="fas fa-pen mr-2"></i>Edit
            </fd-button>
          </div>

          <display-data
            class="col-12 px-1 mb-2"
            label="Email"
            :content="user.email"
          >
          </display-data>
          <display-data
            class="col-12 px-1 mb-2"
            label="Contact No."
            :content="$getUserPhoneNumber(user.user)"
          >
          </display-data>
        </div>
      </div>
      <div class="card flat d-flex justify-content-end p-3 mt-3">
        <fd-button
          class="bordered danger"
          @click="toggleActiveStatus(user, !user.isActive)"
          >{{ user.isActive ? "Ban" : "Unban" }} User</fd-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { user as userAPI } from "@/api";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      user: {
        developer: {
          name: ""
        },
        rolePermission: {
          name: ""
        },
        name: "",
        email: "",
        contactNumber: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getUser();
    },
    async getUser() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await userAPI.getUser(this.$route.params.id);
        this.user = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch user's data. Please try again later."
        });
      }
    },
    toEditPage(id) {
      this.$router.push({ name: "ManageUsersEdit", parmas: { id: id } });
    },
    async toggleActiveStatus(user, statusToSet) {
      let actionLabel = statusToSet ? "unban" : "ban";
      let confirm = await this.$confirm({
        title: "Ban User",
        message: `Are you sure you want to ${actionLabel} user '${user.name}'?`
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await userAPI.updateUserStatus(user.id, { isActive: statusToSet });
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `Status for user '${user.name}' has been updated.`
          });
          this.getUser();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: `Failed to ${actionLabel} user ${user.name}. Please try again later.`
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
